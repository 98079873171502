import { getGameConfig, getGameCurrentRound, IGameConfig, IGameRound } from "@/api";
import { Loader, LoaderSimple, PrimaryButton, useAuthContext } from "@/components";
import { MainTitleWrapper } from "@/components/typography";
import createExcelFile, { getSessionStorage } from "@/helpers";
import { GAME_CONFIG_SS_KEY } from "@/helpers/constants";
import { GameLayout } from "@/layouts";
import { ActorVarsSection, GlobalVarsSection } from "@/pageComponents/dashboard";
import axios from "axios";
import { Link } from "gatsby";
import React, { useEffect, useRef } from "react";

type DashboardRawData = {
	action_id: string[];
	action_name: string[];
	action_round: string[];
	[key: string]: number | string[];
};

const getDashboardRawData = async (pk: string) => {
	const response = await axios.get(`https://api.dev.r-manager.io/game-engine/reports/${pk}/dashboard`);
	return response.data as DashboardRawData;
};

const getDashboardRawDataAdmin = async (pk: string) => {
	const response = await axios.get(`https://api.dev.r-manager.io/game-engine/reports/${pk}/dashboard/actions`);
	return response.data as DashboardRawData;
};

export default function Dashboard() {
	const { userInfo } = useAuthContext();
	const [isDownloadLoading, setIsDownloadLoading] = React.useState(false);
	const [isComputeLoading, setIsComputeLoading] = React.useState(false);
	const [isStartNextRoundLoading, setIsStartNextRoundLoading] = React.useState(false);
	const [isRoundLockLoading, setIsRoundLockLoading] = React.useState(false);
	const [currentRound, setCurrentRound] = React.useState<IGameRound>();
	const downloadLinkRef = useRef<HTMLAnchorElement>(null);

	const gameConfig: IGameConfig = getSessionStorage(GAME_CONFIG_SS_KEY, {} as IGameConfig);
	useEffect(() => {
		if (currentRound) return;
		getGameCurrentRound(gameConfig.pk).then((currentRoundResult) => {
			setCurrentRound(currentRoundResult);
			if (isRoundLockLoading) setIsRoundLockLoading(false);
		});
	}, [currentRound]);
	const onComputeClick = () => {
		setIsComputeLoading(true);
		axios
			.post(`https://api.dev.r-manager.io/game-engine/rounds/compute`, {
				gameId: gameConfig.pk,
				roundId: currentRound.roundId,
			})
			.finally(() => {
				setIsComputeLoading(false);
				window.location.reload();
			});
	};
	const onStartNextRoundClick = () => {
		setIsStartNextRoundLoading(true);
		axios
			.post(`https://api.dev.r-manager.io/game-engine/rounds/close`, {
				gameId: gameConfig.pk,
				roundId: currentRound.roundId,
			})
			.then(() => {
				if (currentRound.roundId === gameConfig.maxRoundId) {
					window.location.reload();
					return;
				}
				axios
					.post(`https://api.dev.r-manager.io/game-manager/rounds/start`, {
						gameId: gameConfig.pk,
						roundId: `${+currentRound.roundId + 1}`,
					})
					.finally(() => {
						setIsStartNextRoundLoading(false);
						window.location.reload();
					});
			});
	};
	const onUpdateRoundClick = () => {
		setIsRoundLockLoading(true);
		axios
			.post(`https://api.dev.r-manager.io/game-engine/rounds/update-lock`, {
				gameId: gameConfig.pk,
				roundId: currentRound.roundId,
			})
			.finally(() => {
				setCurrentRound(undefined);
			});
	};
	const onDownloadClick = async () => {
		setIsDownloadLoading(true);
		try {
			const data = await getDashboardRawData(gameConfig.pk);
			const link = downloadLinkRef.current;
			const dataKeys = Object.keys(data);
			const { action_id: refData } = data;
			const { file, filename } = await createExcelFile({
				filename: `raw_data_${new Date().getTime()}`,
				rows: refData.map((key: string, index: number) => [
					...dataKeys.map((datakey: string) => {
						return data[datakey][index] ?? "NA";
					}),
				]),
				columns: dataKeys.map((key) => {
					return {
						name: key,
						width: 38,
					};
				}),
			});
			link.href = URL.createObjectURL(file);
			link.download = filename;
			link.click();
		} catch (error) {
			console.error("Error downloading the data", error);
		} finally {
			setIsDownloadLoading(false);
		}
	};
	const onDownloadAdminClick = async () => {
		setIsDownloadLoading(true);
		try {
			const data = await getDashboardRawDataAdmin(gameConfig.pk);
			const link = downloadLinkRef.current;
			const dataKeys = Object.keys(data);
			const { action_id: refData } = data;
			const { file, filename } = await createExcelFile({
				filename: `raw_data_${new Date().getTime()}`,
				rows: refData.map((key: string, index: number) => [
					...dataKeys.map((datakey: string) => {
						return data[datakey][index] ?? "NA";
					}),
				]),
				columns: dataKeys.map((key) => {
					return {
						name: key,
						width: 38,
					};
				}),
			});
			link.href = URL.createObjectURL(file);
			link.download = filename;
			link.click();
		} catch (error) {
			console.error("Error downloading the data", error);
		} finally {
			setIsDownloadLoading(false);
		}
	};
	const isZazar = userInfo?.email?.includes("+zazar@");
	// if (!currentRound) {
	// 	return <Loader message="loading..." />;
	// }
	return (
		<GameLayout navbar={true}>
			{isRoundLockLoading && <Loader message="Updating..."></Loader>}
			<div className="flex flex-row justify-end text-xs text-gray-300 mb-4">{gameConfig.pk}</div>
			<div className="flex flex-row">
				<Link type="button" className="hover:underline cursor-pointer" to="/game">
					⬅️ Regresar
				</Link>
			</div>
			<MainTitleWrapper>Dashboard: {gameConfig.title}</MainTitleWrapper>
			{isZazar && currentRound && (
				<div className="bg-white shadow overflow-hidden sm:rounded-lg my-4">
					<div className="px-4 py-2 sm:px-6">
						<h3 className="text-lg font-bold text-gray-900">Game settings</h3>
					</div>
					<div className="border-t border-gray-200">
						<dl>
							<div className="bg-white px-4 py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
								<dt className="text-sm font-medium text-gray-500">Current Round</dt>
								<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">{currentRound.label}</dd>
							</div>
							<div className="bg-white px-4 py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
								<dt className="text-sm font-medium text-gray-500">Initial Round</dt>
								<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">{gameConfig.initialRoundId}</dd>
							</div>
							<div className="bg-white px-4 py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
								<dt className="text-sm font-medium text-gray-500">Max Round</dt>
								<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">{gameConfig.maxRoundId}</dd>
							</div>
							<div className="bg-white px-4 py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
								<dt className="text-sm font-medium text-gray-500">Round Lock</dt>
								<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
									The round is {!!currentRound.isLocked ? "locked" : "unlocked"}
									<PrimaryButton onClick={onUpdateRoundClick} className="ml-2 text-xs">
										{!!currentRound.isLocked ? "Unlock" : "Lock"}
									</PrimaryButton>
								</dd>
							</div>
							<div className="bg-white px-4 py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
								<dt className="text-sm font-medium text-gray-500">Compute Round</dt>
								<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
									<PrimaryButton onClick={onComputeClick} className="text-xs p-2">
										{isComputeLoading ? "Loading..." : `Process round ${currentRound.roundId}`}
									</PrimaryButton>
								</dd>
							</div>
							<div className="bg-white px-4 py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
								<dt className="text-sm font-medium text-gray-500">Next Round</dt>
								<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
									<PrimaryButton onClick={onStartNextRoundClick} className="text-xs p-2">
										{isStartNextRoundLoading ? "Loading..." : currentRound.roundId === gameConfig.maxRoundId ? `Close round ${currentRound.roundId}` : `Start round ${+currentRound.roundId + 1}`}
									</PrimaryButton>
								</dd>
							</div>
						</dl>
					</div>
				</div>
			)}
			<div className="flex flex-row justify-end">
				<PrimaryButton disabled={isDownloadLoading} className="text-xs rounded" onClick={onDownloadClick}>
					{isDownloadLoading ? "Cargando..." : "⤵️ Descargar la data"}
				</PrimaryButton>
				{isZazar && (
					<PrimaryButton disabled={isDownloadLoading} className="text-xs rounded ml-2" onClick={onDownloadAdminClick}>
						{isDownloadLoading ? "Cargando..." : "⤵️ Descargar la data (admin)"}
					</PrimaryButton>
				)}
				<a ref={downloadLinkRef} hidden={true} />
			</div>
			<GlobalVarsSection gameConfig={gameConfig} />
			<ActorVarsSection gameConfig={gameConfig} />
		</GameLayout>
	);
}
