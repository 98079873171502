import { getGameActors, IGameActor, IGameConfig, IGameRound } from "@/api";
import { Loader, LoaderSimple, PrimaryButton } from "@/components";
import { PRE_INITAL_ROUND } from "@/helpers/constants";
import { useSortableData } from "@/hooks";
import { ResponsiveLine, Serie } from "@nivo/line";
import axios from "axios";
import React from "react";

type ActorDataItem = {
	[key: string]: number | string | boolean;
	actorId: string;
	actorName: string;
	actorIsLocked?: boolean;
	um?: number;
	ap?: number;
	UM?: number;
	AP?: number;
};

const getActorsData = async (gameId: string) => {
	const response = await axios.get(`https://api.dev.r-manager.io/game-engine/actors/var-status/${gameId}`);
	return response.data as ActorDataItem[];
};

type ActorStatusTableProps = {
	selectActorListener?: (selectedActor?: SelectedActor) => void;
	selectedActor?: SelectedActor;
	gameConfig: IGameConfig;
};

function ActorStatusTable({ selectActorListener, selectedActor, gameConfig }: ActorStatusTableProps) {
	const [actorsData, setActorsData] = React.useState<ActorDataItem[]>([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const { items, requestSort, sortConfig } = useSortableData(actorsData);
	const loadData = async () => {
		try {
			setIsLoading(true);
			const data = await getActorsData(gameConfig.pk);
			if (data) {
				setActorsData(data);
			}
		} catch (error) {
			console.error("Error retrieving the data", error);
		} finally {
			setIsLoading(false);
		}
	};
	React.useEffect(() => {
		loadData();
	}, []);

	const onUpdateActorLockClick = (actorId: string) => {
		setIsLoading(true);
		axios
			.post(`https://api.dev.r-manager.io/game-engine/actors/update-lock`, {
				gameId: gameConfig.pk,
				actorId,
			})
			.finally(() => {
				loadData();
			});
	};

	const onSelectActor = (actor: ActorDataItem) => {
		if (!selectActorListener) {
			return;
		}
		const selectedActor: SelectedActor = {
			id: actor.actorId,
			name: actor.actorName,
		};
		selectActorListener(selectedActor);
	};
	const getEmojiFor = (name: string) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? (sortConfig.direction === "ascending" ? "⬆️" : "⬇️") : undefined;
	};
	if (isLoading || !items.length) {
		return <LoaderSimple message="Loading table..." />;
	}
	const actorVars = Object.keys(items[0]).reduce((prev, key) => {
		if (key === "actorId" || key === "actorName" || key === "actorIsLocked") return prev;
		return [...prev, key];
	}, [] as string[]);
	return (
		<table className="table-auto border-collapse border">
			<thead>
				<tr className="text-sm">
					<th className="text-white bg-gray-700 p-2">
						<button type="button" className="font-bold" onClick={() => requestSort("actorName")}>
							Actor {getEmojiFor("actorName")}
						</button>
					</th>
					{actorVars.map((av) => {
						return (
							<th className="text-white bg-gray-700  p-2" key={av}>
								<button type="button" className="font-bold" onClick={() => requestSort(av)}>
									{av} {getEmojiFor(av)}
								</button>
							</th>
						);
					})}
					<th className="text-white bg-gray-700 p-2">Bloqueo</th>
				</tr>
			</thead>
			<tbody>
				{items.map((actor, idx) => {
					return (
						<tr key={idx} className={`hover:underline ${selectedActor?.id === actor.actorId ? "bg-gray-200" : ""}`}>
							<td
								className="border p-1 text-sm cursor-pointer"
								onClick={() => {
									onSelectActor(actor);
								}}>
								{actor.actorName}
							</td>
							{actorVars.map((av) => {
								return (
									<td key={av} className="border p-1 text-sm text-right">
										{(+actor[av]).toFixed(2)}
									</td>
								);
							})}
							<td className="border p-1 text-sm cursor-pointer">
								<div>
									<p>El actor está {actor.actorIsLocked ? "bloqueado" : "desbloqueado"}</p>
									<PrimaryButton onClick={() => onUpdateActorLockClick(actor.actorId)} className="text-xs">
										{!!actor.actorIsLocked ? "Desbloquear" : "Bloquear"}
									</PrimaryButton>
								</div>
							</td>
						</tr>
					);
				})}
				<tr>
					<td
						className="text-right text-sm px-1"
						colSpan={3}
						onClick={() => {
							if (!selectActorListener) {
								return;
							}
							selectActorListener();
						}}>
						<span className="cursor-pointer hover:underline">Ver todos</span>
					</td>
				</tr>
			</tbody>
		</table>
	);
}

type HistoricalActorDataItemData = {
	round: string;
	um?: number;
	ap?: number;
	[key: string]: number | string;
};

type HistoricalActorDataItem = {
	actorName: string;
	data: HistoricalActorDataItemData[];
};

type SelectedActor = {
	id: string;
	name: string;
};

const getActorsHistoricalData = async (gameId: string) => {
	const response = await axios.get(`https://api.dev.r-manager.io/game-engine/actors/history/${gameId}`);
	return response.data as { [actorId: string]: HistoricalActorDataItem };
};

type ActorHistoryGraphViewerProps = {
	selectedActor?: SelectedActor;
	gameConfig: IGameConfig;
};

function getRound0HistoryRecord(actor: IGameActor): HistoricalActorDataItemData | undefined {
	if (!actor || !actor.vars) {
		return;
	}
	const { vars } = actor;
	const historyDataItem: HistoricalActorDataItemData = {
		round: PRE_INITAL_ROUND,
	};
	vars.forEach((gv) => (historyDataItem[gv.id] = gv.value));
	return historyDataItem;
}

function ActorHistoryGraphViewer({ selectedActor, gameConfig }: ActorHistoryGraphViewerProps) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [actorsHistoricalData, setActorsHistoricalData] = React.useState<{ [actorId: string]: HistoricalActorDataItem }>({});
	const [currentShownData, setCurrentShownData] = React.useState<Serie[]>([]);
	const [currentShownVar, setCurrentShownVar] = React.useState<string>();
	const [actorVars, setActorVars] = React.useState<string[]>([]);
	const loadData = async () => {
		try {
			setIsLoading(true);
			const data = await getActorsHistoricalData(gameConfig.pk);
			const gameActorsRecord = await getGameActors(gameConfig.pk);
			if (data) {
				gameActorsRecord.forEach((actor) => {
					if (data[actor.id]) {
						const round0HistoryRecord = getRound0HistoryRecord(actor);
						data[actor.id].data.unshift(round0HistoryRecord);
					}
				});
				setActorsHistoricalData(data);
				const tempActorVars = Object.keys(data[gameActorsRecord[0].id].data[0]).reduce((prev, key) => {
					if (key === "round") return prev;
					return [...prev, key];
				}, [] as string[]);
				setActorVars(tempActorVars);
				setCurrentShownVar(tempActorVars[0]);
			}
		} catch (error) {
			console.error("Error retrieving the data", error);
		} finally {
			setIsLoading(false);
		}
	};
	React.useEffect(() => {
		loadData();
	}, []);
	React.useEffect(() => {
		if (!actorsHistoricalData) return;
		if (!currentShownVar) return;

		console.info("actorsHistoricalData", actorsHistoricalData);
		if (!selectedActor) {
			// Show all series
			const keys = Object.keys(actorsHistoricalData);

			const seriesArray: Serie[] = [];
			for (const key of keys) {
				seriesArray.push({
					id: key,
					data: actorsHistoricalData[key].data
						.sort((d, d1) => {
							return +d.round - +d1.round;
						})
						.map((data) => {
							return { x: data.round, y: data[currentShownVar] };
						}),
				});
			}
			setCurrentShownData(seriesArray);
		} else {
			const historicalDataActorItem = actorsHistoricalData[selectedActor.id];
			if (!historicalDataActorItem) return;
			// Show the selected actor serie
			const seriesArray: Serie[] = [];

			seriesArray.push({
				id: selectedActor.id,
				data: historicalDataActorItem.data
					.sort((d, d1) => {
						return +d.round - +d1.round;
					})
					.map((data) => {
						return { x: data.round, y: data[currentShownVar] };
					}),
			});

			setCurrentShownData(seriesArray);
		}
	}, [actorsHistoricalData, selectedActor, currentShownVar]);
	if (isLoading || !actorVars.length || !currentShownVar) {
		return <LoaderSimple message="Loading graph..." />;
	}
	return (
		<div className="p-2">
			<div className="px-4 py-4">
				<select
					value={currentShownVar}
					className="text-xs"
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
						setCurrentShownVar(event.target.value);
					}}>
					{actorVars.map((av) => {
						return (
							<option value={av} key={av}>
								{av}
							</option>
						);
					})}
					{/* <option value="UM">UM</option>
					<option value="AP">AP</option> */}
				</select>
			</div>
			<div className="w-full h-96">
				<ResponsiveLine
					data={currentShownData}
					useMesh={true}
					margin={{
						top: 10,
						right: 50,
						bottom: 20,
						left: 50,
					}}
					axisLeft={{
						tickRotation: 0,
						tickSize: 6,
						tickValues: 5,
						legend: currentShownVar,
						legendOffset: -40,
						legendPosition: "middle",
						format: (v: number) => `${v}`,
					}}
					tooltip={(props) => {
						return (
							<div className="border p-2 text-sm bg-white">
								<p>
									<span className="font-bold">Actor:</span> {props.point.serieId}
								</p>
								<p>
									<span className="font-bold">{currentShownVar}:</span> {`${props.point.data.y}`}
								</p>
							</div>
						);
					}}
					pointSize={8}
					pointColor="#fff"
					pointBorderWidth={2}
					pointBorderColor="#000"
				/>
			</div>
		</div>
	);
}

export function ActorVarsSection({ gameConfig }: { gameConfig: IGameConfig }) {
	const [selectedActor, setSelectedActor] = React.useState<SelectedActor>();
	return (
		<div className="mt-2">
			<h2 className="text-2xl font-bold border-b-2 mb-2">Actores</h2>
			<div className="flex flex-row items-center">
				<div className="w-1/4">
					<ActorStatusTable
						selectActorListener={(actor: SelectedActor) => {
							setSelectedActor(actor);
						}}
						selectedActor={selectedActor}
						gameConfig={gameConfig}
					/>
				</div>
				<div className="w-3/4">
					<ActorHistoryGraphViewer selectedActor={selectedActor} gameConfig={gameConfig} />
				</div>
			</div>
		</div>
	);
}
